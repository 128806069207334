<template>
    <div class="payment-setting">
        <edit :api="'settings'" :fs="paymentFields" :id="'payment'" @saved="saved" ref="edit" title="Payment Setting">
            <template slot="title" slot-scope="{ f }">
                <h3>{{ f.heading }}</h3>
            </template>
        </edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { paymentFields } from "@/config";
import Submit from "@/components/Submit";

export default {
    components: {
        Edit,
        Submit
    },
    computed: {
        paymentFields() {
            return [
                { heading: "Security Deposit ", data: "value.security_deposit", type: "number", validate: "required|min_value: 0" },
                { heading: "Stripe", data: "title" },
                { heading: "Use Stripe", data: "value.use_stripe", type: "boolean" },
                { heading: "App Fee %", data: "value.amount_percent", type: "number", validate: "required|min_value: 0|max_value: 100" },
                { heading: "Client Id", data: "value.stripe_client_id", type: "text", validate: "required" },
                { heading: "Public Key", data: "value.stripe_public_key", type: "text", validate: "required" },
                { heading: "Secret Key", data: "value.stripe_secret_key", type: "text", validate: "required" },
                { heading: "Webhook Secret", data: "value.stripe_webhook_secret", type: "text", validate: "required" },
                { heading: "Webhook Secret (Connect)", data: "value.stripe_connect_webhook_secret", type: "text", validate: "required" },
                { heading: "EzTimePay", data: "title" },
                { heading: "Use Tilled", data: "value.use_tilled", type: "boolean" },
                { heading: "App Fee %", data: "value.tilled_amount_percent", type: "number", validate: "required|min_value: 0|max_value: 100" },
                { heading: "Secret Key", data: "value.tilled_secret_key", type: "text", validate: "required" },
                { heading: "Public Key", data: "value.tilled_public_key", type: "text", validate: "required" },
                { heading: "Client Id", data: "value.tilled_client_id", type: "text", validate: "required" }, // partner account id
                { heading: "App Fee Merchant Id", data: "value.tilled_app_fee_merchant_id", type: "text", validate: "required" }, // Appfee Merchant Id
                { heading: "Webhook Secret (Connect)", data: "value.tilled_connect_webhook_secret", type: "text", validate: "required" }
            ];
        }
    },
    methods: {
        saved() {
            this.$refs["edit"].load();
        }
    },
    created() {}
};
</script>
